import React from "react";
import styles from "./styles.scss"
import CancelIcon from '@mui/icons-material/Cancel';

const TagsHolder = (props) => {
    const {
        maxTags,
        onShowAll,
        showRemoveIcon,
        onTagClicked,
        tags = [],
        onRemoveClicked
    } = props

    var tagsRendered = 0

    const onCancelIcon = (e) => {
        e.stopPropagation()
        onRemoveClicked(tag)
    }

    return (
        <div {...props} className="display-horizontal tag-holder">
            {tags != null && tags.map(tag => {
                if (maxTags == null || tagsRendered < maxTags) {
                    tagsRendered = tagsRendered + 1
                    // setTagsRendered(tagsRendered + 1)
                    return (
                        <div key={tag.id} onClick={() => onTagClicked(tag)} className="tag" style={{ backgroundColor: tag.color_hex_code }}>
                            {tag.display_name}
                            {showRemoveIcon && <CancelIcon onClick={onCancelIcon} className="clickable-icon show-on-tag-hover end-button"/>}
                        </div>
                    )
                } else {
                    return <></>
                }
            })}
            {maxTags != null && maxTags < tags.length && <div onClick={onShowAll} className="tag-extra">+ view all</div>}
        </div>
    )
}

export default TagsHolder
