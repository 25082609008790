import axios from "axios";
import { useEffect, useState } from "react";


// Hook
export function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }


// export function badIframe(iframe) {
//     return new Promise(resolve => {
//         // This uses the new Fetch API to see what happens when the src of the iframe is fetched from the webpage.
//         // This approach would also work with XHR. It would not be as nice to write, but may be preferred for compatibility reasons.
//         fetch(iframe.src)
//             .then(res => {
//                 // the res object represents the response from the server

//                 // res.ok is true if the repose has an "okay status" (200-299)
//                 if (res.ok) {
//                   console.log("is ok")
//                     resolve(false);
//                 } else {
//                   console.log("is not ok")
//                     resolve(true);
//                 }

//                 /* Note: it's probably possible for an iframe source to be given a 300s
//                 status which means it'll redirect to a new page, and this may load
//                 property. In this case the script does not work. However, following the
//                 redirects until an eventual ok status or error is reached would be much
//                 more involved than the solution provided here. */
//             })
//             .catch(()=> resolve(true));
//     });
// }

export function badIframe(url) {
  return new Promise(resolve => {
    axios.get(url)
    .then(function (response) {
      resolve(response.headers["X-Frame-Options"] == "sameorigin")
    })
    .catch(function (error) {
      resolve(true)
    })
    // var req = new XMLHttpRequest();
    // req.open('GET', url, false);
    // req.send(null);
    // var headers = req.getAllResponseHeaders().toLowerCase();
    // resolve(headers["X-Frame-Options"] == "sameorigin")
  })
}