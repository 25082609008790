import { Button } from "@material-ui/core";
import axios from "axios";
import React from "react";
import { Link, Redirect } from "react-router-dom";
import { ApiHelper } from "./ApiHelper";
import ResourcePreviewTile from "./resource/ResourcePreviewTile";
import MButton from "./shared/custom_components/MButton";
import TagsHolder from "./shared/custom_components/TagsHolder";
import ErrorMessage from "./shared/custom_components/ErrorMessage";
import { isMobile } from "./shared/GlobalHelper";
import TagCreatorModal from "./tags/TagCreatorModal";

export default ({
  currentUser
}) => {
  const [redirectToUrl, setRedirectToUrl] = React.useState(null)
  const [tagsErrorMessage, setTagsErrorMessage] = React.useState(null)
  const [resourceErrorMessage, setResourceErrorMessage] = React.useState(null)

  const [tags, setTags] = React.useState([])
  const [resources, setResources] = React.useState([])
  const [initialized, setInitialized] = React.useState(false)
  const [showTagCreateModal, setShowTagCreateModal] = React.useState(false)

  const onBrowseFullResourseLibrary = () => {
    setRedirectToUrl(`/resource/1`)
  }

  const onTagSelected = (tag) => {
    setRedirectToUrl(`/tag/${tag.id}`)
  }

  const onAddNewTag = () => {
    setShowTagCreateModal(true)
  }

  const onTagCreated = (tag) => {
    setTags([...tags, tag])
    setShowTagCreateModal(false)
  }

  const openResource = (resourceId) => {
    setRedirectToUrl(`/resource/${resourceId}`)
  }

  const showAllTags = () => {
    setRedirectToUrl(`/tags`)
  }

  const loadTags = () => {
    axios.get(ApiHelper.GET_ALL_TAGS)
    .then(function (response) {
      if (response.data.tags != null) {
        setTags(response.data.tags)
      }
    })
    .catch(function (error) {
      setTagsErrorMessage("We couldn't find these tags. Sorry for the inconvenience, try again later.")
    })
  }

  const loadFeaturedResources = () => {
    axios.get(ApiHelper.GET_FEATURED_RESOURCES)
    .then(function (response) {
      if (response.data.resources != null) {
        setResources(response.data.resources)
      }
    })
    .catch(function (error) {
      setResourceErrorMessage("We couldn't find the featured resources. Sorry for the inconvenience, try again later.")
    })
  }

  React.useEffect(() => {
    if (!initialized) {
      setInitialized(true)
      loadTags()
      loadFeaturedResources()
    }
  }, [initialized]);

  return (
  <div className="standard-margin">
    <div>Share facts & data. Discuss your thoughts on it.</div>
    {/* <div>This platform aims to make facts & data accessible to allow for productive discussion around the data.</div> */}
    <br/>

  <div className={isMobile() ? "display-vertical" : "display-horizontal"}>
      <div className={isMobile() ? "mobile-tags-section" : "tags-section"}>
        <div className="display-horizontal">
          <h2>browse by tags</h2>
          {currentUser != null && currentUser.isAdmin && <div className="action-button"><MButton color={"primary"} onClick={onAddNewTag}>add tags</MButton></div>}
        </div>
        {tagsErrorMessage != null && <ErrorMessage message={tagsErrorMessage}/>}
        {tags.length == 0 && <div className="empty-text">There are no tags to display.</div>}
        <TagsHolder onTagClicked={onTagSelected} tags={tags} maxTags={7} onShowAll={showAllTags}/>
      </div>

      <div className="full-library-section"><MButton onClick={onBrowseFullResourseLibrary} color={"primary"}>browse full resource library</MButton></div>
    </div>

    <br></br>
    <h2>featured resources</h2>
    {resourceErrorMessage != null && <ErrorMessage message={resourceErrorMessage}/>}
    {resources.length == 0 && <div className="empty-text">There are no featured resources to display.</div>}
    {resources.map(resource => <div className="tile-holder" onClick={() => openResource(resource.id)}><ResourcePreviewTile resource={resource}></ResourcePreviewTile></div>)}
    <div className="view-more-resources-section" onClick={() => setRedirectToUrl(`/resources`)}>view more resources</div>
    <br/>

    {redirectToUrl != null && <Redirect to={redirectToUrl} push={true} />}
    {showTagCreateModal && <TagCreatorModal onClose={() => setShowTagCreateModal(false)} onTagCreated={onTagCreated} />}
  </div>
);
}
