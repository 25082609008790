import React from 'react'
import BaseModal from './BaseModal'
import MButton from './MButton'
import MTextArea from './MTextArea'

const CreateFolderModal = (props) => {
    const [folderName, setFolderName] = React.useState("")

    return (
        <BaseModal>
            <div className="display-horizontal">
                <div className="title">create folder</div>
                <MButton onClick={props.onClose} className="close-modal-button">close</MButton>
            </div>
            <MTextArea value={folderName} setValue={setFolderName} placeholder="file name"/>
            <MButton onClick={() => props.onCreateFolder(folderName)} disabled={folderName == ""} variant="contained">create</MButton>
        </BaseModal>
    )
}

export default CreateFolderModal
