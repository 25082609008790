import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core"
import { useDebounce } from "../ActionHelper.jsx";
import { CheckCircleRounded, ErrorRounded } from "@material-ui/icons";
import styles from "./styles.scss"

export const ValidationStatus = {
    VALID: "VALID",
    NOT_VALID: "NOT_VALID"
}

export const ValidationIndicatorType = {
    NONE: "NONE",
    ICON_END: "ICON_END",
    BOTTOM_MESSAGE: "BOTTOM_MESSAGE"
}

export const MTextValidationResponse = (status, message) => {
    return {
        status,
        message
    }
}

const MTextField = ({
    value,
    setValue,
    // Returns MTextValidationResponse
    validation,
    validationIndicator,
    type = "text",
    placeholder,
    label,
    required
}) => {
    const [validationStatusIcon, setValidationStatusIcon] = useState(null)
    const [validationResponse, setValidationResponse] = useState(null)

    const debouncedValue = useDebounce(value, 500);

    useEffect(
        () => {
          if (debouncedValue) {
              if (validation != null) {
                var response = Promise.resolve(validation(debouncedValue))
                response.then(result => {
                    setValidationResponse(result)
                })
              }
          } else {
            setValidationResponse(null)
          }
        },
        [debouncedValue]
    );

    useEffect(() => {
        if (validationResponse != null) {
            switch (validationResponse.status) {
                case ValidationStatus.NOT_VALID:
                    setValidationStatusIcon(<ErrorRounded className="item red-icon"></ErrorRounded>)
                    return
                case ValidationStatus.VALID:
                    setValidationStatusIcon(<CheckCircleRounded className="item green-icon"></CheckCircleRounded>)
                    return
                default:
                    return
            }
        }
    }, [validationResponse]);

    const getClassName = () => {
        if (validationIndicator == ValidationIndicatorType.BOTTOM_MESSAGE) {
            return "text_field_holder"
        } else {
            return "text_field_holder_horizontal"
        }
    }

    return (
        <div className={getClassName()}>
            <TextField type={type} required={required} label={label} placeholder={placeholder} value={value} onChange={e => setValue(e.target.value)} style={{ width: "100%" }}></TextField>
            {validationIndicator == ValidationIndicatorType.ICON_END && <div className="status_icon">
                {validationStatusIcon}
            </div>}
            {validationIndicator == ValidationIndicatorType.BOTTOM_MESSAGE && validationResponse != null && <div className="field_validation_row">
                {validationStatusIcon}
                <div className="item">{validationResponse.message}</div>
            </div>}
        </div>
    )
}

export default MTextField
