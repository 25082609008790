import axios from 'axios'
import React from 'react'
import MButton from '../shared/custom_components/MButton'
import TagsHolder from '../shared/custom_components/TagsHolder'
import { Redirect } from "react-router-dom";
import TagCreatorModal from "./TagCreatorModal";
import { ApiHelper } from '../ApiHelper';
import ErrorMessage from '../shared/custom_components/ErrorMessage';

const TagsPage = ({
    currentUser
}) => {
    const [redirectToUrl, setRedirectToUrl] = React.useState(null)
    const [errorMessage, setErrorMessage] = React.useState(null)

    const [tags, setTags] = React.useState([])
    const [initialized, setInitialized] = React.useState(false)
    const [showTagCreateModal, setShowTagCreateModal] = React.useState(false)

    const onTagSelected = (tag) => {
        setRedirectToUrl(`/tag/${tag.id}`)
      }
    
      const onAddNewTag = () => {
        setShowTagCreateModal(true)
      }
    
      const onTagCreated = (tag) => {
        setTags([...tags, tag])
        setShowTagCreateModal(false)
      }
    
      const loadTags = () => {
        axios.get(ApiHelper.GET_ALL_TAGS)
        .then(function (response) {
          setTags(response.data.tags)
        })
        .catch(function (error) {
          setErrorMessage("Something went wrong trying to find available tags. Sorry for the inconvenience, try again later.")
        })
      }

      React.useEffect(() => {
        if (!initialized) {
          setInitialized(true)
          loadTags()
        }
      }, [initialized]);

    return (
        <div className="standard-margin">
            <div className="display-horizontal">
                <h2>browse by tags</h2>
                {currentUser != null && currentUser.isAdmin && <div className="action-button"><MButton onClick={onAddNewTag}>add tags</MButton></div>}
            </div>
            {errorMessage != null && <ErrorMessage message={errorMessage} />}
            <TagsHolder onTagClicked={onTagSelected} tags={tags}/>

            {redirectToUrl != null && <Redirect to={redirectToUrl} push={true} />}
            {showTagCreateModal && <TagCreatorModal onClose={() => setShowTagCreateModal(false)} onTagCreated={onTagCreated} />}
        </div>
    )
}

export default TagsPage
