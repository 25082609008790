import React from "react";
import CommentEditor from "./CommentEditor";
import Comment from "./Comment";
import styles from './styles'

const CommentsHolder = (props) => {
    return (
        <div {...props}>
            <CommentEditor currentUser={props.currentUser} addComment={props.addComment}/>
            <div className="comments-divider"/>
            <div className="comments-viewer-window">
                {props.comments.map(comment => <Comment key={comment.id} comment={comment} currentUserId={props.currentUser != null ? props.currentUser.id : null} isAdmin={props.isAdmin} onDeleteComment={props.onDeleteComment}/>)}
            </div>
        </div>
    )
}

export default CommentsHolder
