import React from "react";
import styles from "./styles.scss"
import MTextArea from '../shared/custom_components/MTextArea.jsx'
import MTextField from '../shared/custom_components/MTextField.jsx'
import MButton from "../shared/custom_components/MButton";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import axios from "axios";
import { ApiHelper, ApiErrors, ApiErrorValues } from "../ApiHelper";
import { Redirect } from "react-router-dom";
import ErrorMessage from "../shared/custom_components/ErrorMessage";

const ResourceCreator = ({
    currentUser,
    parentResourceId
}) => {
    // Utility
    const [redirectToUrl, setRedirectToUrl] = React.useState(null)

    // Data
    const [resourceTitle, setResourceTitle] = React.useState("")
    const [notes, setNotes] = React.useState("")
    const [sourceUrl, setSourceUrl] = React.useState("")
    const [sourceContent, setSourceContent] = React.useState("")
    const [sourceBackground, setSourceBackground] = React.useState("")
    const [tags, setTags] = React.useState([])
    const [resourceLinks, setResourceLinks] = React.useState([])
    const [contentType, setContentType] = React.useState("HTML")
    const [selectedParentResourceId, setSelectedParentResourceId] = React.useState(parentResourceId)

    // Messaging
    const [isValidated, setIsValidated] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState(null)
    const [validationMessage, setValidationMessage] = React.useState("title, content (add a URL or text)")

    const createResource = () => {
        if (isValidated) {
            const body = {
                display_name: resourceTitle,
                notes: notes,
                file_type: contentType,
                source_url: sourceUrl != "" ? sourceUrl : null,
                body: sourceContent != "" ? sourceContent : null,
                source_info: sourceBackground,
                tags: tags,
                links: resourceLinks,
                publisher_id: currentUser.id,
                parent_resource_id: selectedParentResourceId
            }
            axios.post(ApiHelper.CREATE_RESOURCE(currentUser.id), body)
            .then(function (response) {
                setRedirectToUrl(`/resource/${response.data.id}`)
            })
            .catch(function (error) {
                setErrorMessage("Oh no! Something went wrong. We were not able to create this resource, try again later.")
            })
        } else {
            setErrorMessage(`You are still missing required fields: ${validationMessage}`)
        }
    }

    const openLinkedResourceOptions = () => {
        // TODO: open option to remove linked resource
    }

    const openAddTagsModal = () => {
        // TODO: Add tags modal
    }

    const openAddResourcesModal = () => {
        // TODO: Add resources modal
    }

    const validate = () => {
        const titleValidity = resourceTitle != ""
        const contentTypeValidity = (contentType == "HTML" && sourceUrl != "") || (contentType == "RAWTXT" && sourceContent != "")
        const validityStatus = titleValidity && contentTypeValidity
        setIsValidated(validityStatus)
        if (validityStatus) {
            setValidationMessage(`${titleValidity && "title, "}${contentTypeValidity && "content (add a URL or text) "}`)
        }
    }

    React.useEffect(() => {
        validate()
    }, [resourceTitle, sourceUrl, sourceContent, contentType]);

    return (
        <>
        {currentUser != null 
        ? <div className="standard-margin">
            <div className="title">publish new source</div>
            <div>Give the source a title.</div>
            <MTextField  value={resourceTitle} setValue={setResourceTitle}></MTextField>
            <div className="title-sub-note">published by: {currentUser.display_name} (you)</div>

            <div className="resource-section-holder notes">
                <div><b>notes</b></div>
                <div>Add some notes. These are intended for any thoughts or comments you have on the source.</div>
                <MTextArea value={notes} setValue={setNotes} placeholder={"notes here"}></MTextArea>
            </div>

            <div className="resource-section-holder">
                <div><b>content</b></div>
                <br/>
                <div className="display-horizontal">
                    {contentType == "HTML" ? <RadioButtonCheckedIcon className="radio-button"/> : <RadioButtonUncheckedIcon className="radio-button" onClick={() => setContentType("HTML")}/> }
                    <br/>
                    <div className={contentType == "HTML" ? "display-vertical" : "display-vertical unfocused"}>
                        <div>Use a website as a source. Add the url here:</div>
                        <MTextField  value={sourceUrl} setValue={setSourceUrl}></MTextField>
                    </div>
                </div>
                
                <br/>

                <div className="display-horizontal">
                    {contentType == "RAWTXT" ? <RadioButtonCheckedIcon className="radio-button"/> : <RadioButtonUncheckedIcon className="radio-button" onClick={() => setContentType("RAWTXT")}/> }
                    <br/>
                    <div className={contentType == "RAWTXT" ? "display-vertical" : "display-vertical unfocused"}>
                        <div>Use text as a source. Add the source content body here:</div>
                        <MTextArea value={sourceContent} setValue={setSourceContent} placeholder={"type your source content"}></MTextArea>
                    </div>
                </div>
            </div>

            <div className="resource-section-holder notes">
                <div><b>source background</b></div>
                <div>Add some background for the source. Add additional links or notes that are relevant to context or rebutability of the source.</div>
                <MTextArea value={sourceBackground} setValue={setSourceBackground} placeholder={"source info here"}></MTextArea>
            </div>

            <br/>
            <MButton onClick={createResource} disabled={!isValidated}>publish</MButton>
            {errorMessage != null && <ErrorMessage message={errorMessage}/>}
            <br/>
        </div>
        : ApiErrors.getErrorPage(ApiErrorValues.NOT_FOUND)}

        {redirectToUrl != null && <Redirect to={redirectToUrl} push={true} />}
        </>
    )
}

export default ResourceCreator
