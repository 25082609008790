import React from 'react'
import styles from './styles'
import { Link } from 'react-router-dom'

const SomethingWentWrongPage = ({

}) => {
    return (
        <div className="full-error-page">
            <div className="center-message">
                <img className="header-image" src="https://media4.giphy.com/media/EcBihYnTLQdvSuGWxD/200.webp?cid=ecf05e47slq0hr3rfeyqype1i5vww9xaa3ar2tsmwwqo62t0&rid=200.webp&ct=g"></img>
                <img className="header-image" src="https://media3.giphy.com/media/vyTnNTrs3wqQ0UIvwE/200.webp?cid=ecf05e47bdzs0jlu3ox3vuo0lw68y75xra3fw6sphwg4x4uf&rid=200.webp&ct=g"></img>
            </div>
            <div className="center-message">
                <h1>uh oh, something went wrong</h1>
            </div>
        </div>
    )
}

export default SomethingWentWrongPage