import axios from "axios";
import React from "react";
import { ApiHelper } from "../ApiHelper";
import ErrorMessage from "../shared/custom_components/ErrorMessage";
import TagsHolder from "../shared/custom_components/TagsHolder";

const TagPickerModule = ({
    onTagPicked
}) => {
    const [tags, setTags] = React.useState([])
    const [initialized, setInitialized] = React.useState(false)

    const [errorMessge, setErrorMessge] = React.useState(null)

    const fetchAvailableTags = () => {
        setErrorMessge(null)
        axios.get(ApiHelper.GET_ALL_TAGS)
        .then(function (response) { setTags(response.data.tags) })
        .catch(function (error) { setErrorMessge("Failed to fetch available tags.") })
    }

    React.useEffect(() => {
        if (!initialized) {
            setInitialized(true)
            fetchAvailableTags()
        }
    }, [initialized]);

    return (
        <div >
            <TagsHolder tags={tags} onTagClicked={onTagPicked}></TagsHolder>
            {errorMessge != null && <ErrorMessage message={errorMessge}/>}
        </div>
    )
}

export default TagPickerModule
