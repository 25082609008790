import React from "react";
import Error404Page from './shared/errors/Error404Page'
import NotLoggedInPage from './shared/errors/NotLoggedInPage'
import ExpiredSessionPage from './shared/errors/ExpiredSessionPage'
import SomethingWentWrongPage from './shared/errors/SomethingWentWrongPage'

const baseUrl = process.env.BASE_DOMAIN_URL
const baseEndpoint = `${baseUrl}/api/v1/`

export const ApiHelper = {
    GET_CURRENT_USER: () => baseEndpoint + "users/current",
    CHECK_USERNAME_VALIDITY: (username) => baseEndpoint + `user/${username}/username_validity_check`,
    CHECK_TAG_VALIDITY: (name) => baseEndpoint + `tag/${name}/validity_check`,
    CREATE_NEW_USER: baseEndpoint + "users/create",
    AUTHENTICATE_USER: baseEndpoint + "users/login",
    UPDATE_USER: (user_id) => baseEndpoint + `user/${user_id}/update`,
    UPDATE_RESOURCE: (resource_id) => baseEndpoint + `resource/${resource_id}/update`,
    ADD_COMMENT: (resource_id) => baseEndpoint + `/resource/${resource_id}/add_comment`,
    CREATE_RESOURCE: (user_id) => baseEndpoint + `user/${user_id}/resource/create`,
    GET_RESOURCE: (resource_id) => baseEndpoint + `resource/${resource_id}`,
    GET_USER_RESOURCES: (user_id) => baseEndpoint + `user/${user_id}/resources`,
    GET_ALL_TAGS: baseEndpoint + "tags",
    GET_FEATURED_RESOURCES: baseEndpoint + "resources/featured",
    GET_TAGGED_RESOURCES: (tag_id) => baseEndpoint + `tag/${tag_id}`,
    CREATE_TAG: baseEndpoint + "tags/create",
    TAG_RESOURCE: (resource_id, tag_id) => baseEndpoint + `resource/${resource_id}/tag/${tag_id}`,
    UNTAG_RESOURCE: (resource_id, tag_id) => baseEndpoint + `resource/${resource_id}/untag/${tag_id}`,
    GET_RESOURCE_TAGS: (resource_id) => baseEndpoint + `resource/${resource_id}/tags`,
    ADD_RESOURCE_LINK: (parent_resource_id) => baseEndpoint + `resource/${parent_resource_id}/add_link`,
    UNLINK_RESOURCE: (parent_resource_id, sub_link_id) => baseEndpoint + `/resource/${parent_resource_id}/unlink/${sub_link_id}`,
    GET_RESOURCE_SUBLINKS: (parent_resource_id) => baseEndpoint + `resource/${parent_resource_id}/sublinks`,
    GET_ALL_RESOURCES: baseEndpoint + `resources/all`,
    DELETE_COMMENT: (comment_id) => baseEndpoint + `comment/${comment_id}/delete`,
    GET_RESOURCE_COMMENTS: (resource_id) => baseEndpoint + `resource/${resource_id}/comments`,
    CREATE_FOLDER: (parent_resource_id) => baseEndpoint + `resource/${parent_resource_id}/new_folder`,
    UPDATE_RESOURCE_PREVIEW_IMAGE: (resource_id) => baseEndpoint + `resource/${resource_id}/preview_image`,
    UPDATE_USER_PROFILE_IMAGE: (user_id) => baseEndpoint + `user/${user_id}/profile_image`,
    DELETE_RESOURCE: (resource_id) => baseEndpoint + `resource/${resource_id}`,
    GET_SUMMARY_COMMENTS: () => baseEndpoint + `comments/summarized`
}

export const ApiValuesHelper = {
    getAuthToken: () => {
        var token = localStorage.getItem('session_token')
        if (token == null) {
            return null
        }
        return `Bearer ${token}`
    },
    setAccessToken: (token) => localStorage.setItem('session_token', token),
    clearAccessToken: () => localStorage.removeItem('session_token'),
    defaultHeaders: () => {
        var authToken = ApiValuesHelper.getAuthToken()
        if (authToken != null) {
            return {
                "Authorization": authToken,
                "Access-Control-Allow-Origin": "https://www.thecanceledculture.com"
            }
        } else {
            return {
                "Access-Control-Allow-Origin": "https://www.thecanceledculture.com"
            }
        }
    }
}

export const ApiErrorValues = {
    UNDEFINED: "API_ERROR_UNDEFINED",
    UNAUTHORIZED: "API_ERROR_UNAUTHORIZED", // 403
    MISSING_AUTH: "API_ERROR_MISSING_AUTH", // 401
    NOT_FOUND: "API_ERROR_NOT_FOUND", // 404
    EXPIRED_SESSION: "API_ERROR_EXPIRED_SESSION" // 599
}

export const ApiErrors = {
    getValueForErrorResponse: (status) => {
        switch (status) {
            case 403:
                return ApiErrorValues.UNAUTHORIZED
            case 401:
                return ApiErrorValues.MISSING_AUTH
            case 404:
                return ApiErrorValues.NOT_FOUND
            case 599:
                return ApiErrorValues.EXPIRED_SESSION
            default:
                return ApiErrorValues.UNDEFINED
        }
    },
    getErrorPage: (errorValue) => {
        switch (errorValue) {
            case ApiErrorValues.UNAUTHORIZED:
                return <Error404Page></Error404Page>
            case ApiErrorValues.MISSING_AUTH:
                return <NotLoggedInPage></NotLoggedInPage>
            case ApiErrorValues.NOT_FOUND:
                return <Error404Page></Error404Page>
            case ApiErrorValues.EXPIRED_SESSION:
                ApiValuesHelper.clearAccessToken()
                return <ExpiredSessionPage></ExpiredSessionPage>
            default:
                return <SomethingWentWrongPage></SomethingWentWrongPage>
        }
    }
}

// export const request = {
//     get: (url) => axios.get(url)
// }
