import axios from "axios";
import React from "react";
import { ApiHelper } from "../ApiHelper";
import Library from "./Library";
import ResourceDetail from "./ResourceDetail";
import styles from "./styles.scss"

const ResourceRouter = ({
    currentUser,
    resourceId,
    tagId
}) => {
    const [resource, setResource] = React.useState(null)

    const loadResource = () => {
        axios.get(ApiHelper.GET_RESOURCE(resourceId))
        .then(function (response) {
            setResource({...response.data, isTag: false})
        })
        .catch(function (error) {
            console.log("failed to load resource")
        })
    }

    const loadTaggedResources = () => {
        axios.get(ApiHelper.GET_TAGGED_RESOURCES(tagId))
        .then(function (response) {
            setResource({ display_name: response.data.tag.display_name, is_folder: true, isTag: true, sub_links: response.data.resources })
        })
        .catch(function (error) {
            console.log("failed to load resource")
        })
    }

    React.useEffect(() => {
        if (resourceId != null) {
            loadResource()
        }
    }, [resourceId]);

    React.useEffect(() => {
        if (tagId != null) {
            loadTaggedResources()
        }
    }, [tagId]);

    return (
        <div>
            {resource != null 
            ? resource.is_folder ? <Library parentResource={resource} isAdmin={currentUser != null ? currentUser.isAdmin : false}></Library> : <ResourceDetail currentUser={currentUser} resource={resource} isAdmin={currentUser != null ? currentUser.isAdmin : false}></ResourceDetail>
            : <div>loading...</div>}
        </div>
    )
}

export default ResourceRouter
