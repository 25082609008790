import axios from "axios";
import React from "react";
import { ApiHelper } from "../../ApiHelper";
import MTextArea from "../../shared/custom_components/MTextArea";
import styles from "./styles.scss"
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import MButton from "../../shared/custom_components/MButton";
import ErrorMessage from "../../shared/custom_components/ErrorMessage";

const RawTxtContentBlock = (props) => {
    const {isAdmin, resourceId, content} = props

    const [sourceContent, setSourceContent] = React.useState(content != null ? content : "")
    const [editingSourceContent, setEditingSourceContent] = React.useState(null)
    const [errorMessage, setErrorMessage] = React.useState(null)

    const updateSourceContent = () => {
        const data = {
            resource: {
                edit_type: "RAWTXT",
                body: editingSourceContent
            }
        }
        axios.patch(ApiHelper.UPDATE_RESOURCE(resourceId), data)
        .then(function (response) { 
            setSourceContent(response.data.body)
            setEditingSourceContent(null)
        })
        .catch(function (error) { setErrorMessage("Failed to update content. Try again later.") })
    }

    return (
        <div {...{props}} className="resource-section-holder content">
            <div className="display-horizontal">
                <div><b>content</b></div>
                <div className="spacer"/>
                {errorMessage && <ErrorMessage message={errorMessage}/>}
                {isAdmin && editingSourceContent != null ? <div><SaveIcon className="clickable-icon" onClick={updateSourceContent}/> <CancelIcon className="clickable-icon" onClick={() => setEditingSourceContent(null)}/></div> : <EditIcon className={isAdmin ? "clickable-icon hide-til-hover-child" : "hide-item"} onClick={() => setEditingSourceContent(sourceContent)}/>}
            </div>
            {sourceContent != null && <br/>}
            {isAdmin && editingSourceContent != null && <div className="raw-text-content-holder"><MTextArea value={editingSourceContent} setValue={setEditingSourceContent}/></div>}
            {editingSourceContent == null && <div>
                <div className="raw-text-content-holder">
                    {sourceContent}
                </div>
            </div>}
        </div>
    )
}

export default RawTxtContentBlock
