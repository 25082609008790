import React from 'react'
import styles from './styles'
import { Link } from 'react-router-dom'

const Error404Page = ({

}) => {
    return (
        <div className="full-error-page">
            <div className="center-message">
                <img className="header-image" src="https://i.pinimg.com/originals/6d/4d/c1/6d4dc1a507a0e684f3ccae8ac7a81b3e.gif"></img>
            </div>
            <div className="center-message">
                <h1>404</h1>
            </div>
            <div className="center-message">
                <h1>we couldn't find your, maybe it's in the lost and found...</h1>
            </div>
        </div>
    )
}

export default Error404Page