import axios from "axios";
import React from "react";
import styles from './styles.scss'
import { TwitterPicker } from "react-color";
import { ApiHelper, ApiValuesHelper } from "../ApiHelper";
import MButton from "../shared/custom_components/MButton";
import MTextField, { ValidationIndicatorType, ValidationStatus } from "../shared/custom_components/MTextField";

function checkTag(tag_name) {
    return axios.get(ApiHelper.CHECK_TAG_VALIDITY(tag_name), { headers: ApiValuesHelper.defaultHeaders() })
    .then(function (response) { 
        return response.data.validity 
    })
    .catch(function (error) { return false });
}

const TagCreatorModule = ({
    onTagCreated = (tag) => {}
}) => {
    const defaultColors = [
        "#FF6900",
        "#FCB900",
        "#7BDCB5",
        "#00D084",
        "#8ED1FC",
        "#0693E3",
        "#ABB8C3",
        "#EB144C",
        "#F78DA7",
        "#9900EF"
    ]
    const pickRandomColor = () => {
        const max = defaultColors.length - 1
        return defaultColors[ Math.floor(Math.random() * max) ]
    }

    const [displayName, setDisplayName] = React.useState("")
    const [colorHex, setColorHex] = React.useState(pickRandomColor())

    const [displayNameError, setDisplayNameError] = React.useState(false)
    const [createError, setCreateError] = React.useState(null)

    const createTag = () => {
        const data = {
            display_name: displayName,
            color_hex_code: colorHex
        }
        axios.post(ApiHelper.CREATE_TAG, data)
        .then(function (response) { onTagCreated(response.data) })
        .catch(function (error) { setCreateError("Oh no! Something went wrong creating the tag.") })
    }

    const handleColorSelect = (color) => {
        setColorHex(color.hex)
    }

    const validateTagName = (input) => {
        var validity = checkTag(input).then(result => {
            var status = ValidationStatus.NOT_VALID
            var message = "this username is available"
            if (result == true) {
                setDisplayNameError(false)
                status = ValidationStatus.VALID
                message = "this username is available"
            } else {
                setDisplayNameError(true)
                status = ValidationStatus.NOT_VALID
                message = "this username is not available, try something else"
            }
            return { status, message }
        });
        return validity
    }

    return (
        <div>
            <div><b>new tag:</b></div>
            <MTextField placeholder={"tag name here"} value={displayName} setValue={setDisplayName} validation={validateTagName} validationIndicator={ValidationIndicatorType.ICON_END} />
            <div className="display-horizontal">
                <TwitterPicker color={colorHex} onChangeComplete={handleColorSelect}/>
                <div className="display-vertical standard-margin">
                    <div><b>selected color</b></div>
                    <div>{colorHex}</div>
                    <div style={{ backgroundColor: colorHex, borderRadius: "16px", width: "50px", height: "50px" }}></div>
                </div>
            </div>
            
            <div className="modal-button"><MButton onClick={createTag} disabled={displayNameError || displayName == ""}><b>create tag</b></MButton></div>
            {createError != null && <div className="display-horizontal">
                <div>{createError}</div>
            </div>}
        </div>
    )
}

export default TagCreatorModule
