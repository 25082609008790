import React from "react";
import styles from './styles.scss'
import BaseModal from "../shared/custom_components/BaseModal";
import MButton from "../shared/custom_components/MButton";
import TagCreatorModule from "./TagCreatorModule";

const TagCreatorModal = ({
    onTagCreated,
    onClose
}) => {

    return (
        <BaseModal>
            <TagCreatorModule onTagCreated={onTagCreated}/>
            <MButton onClick={onClose}>close</MButton>
        </BaseModal>
    )
}

export default TagCreatorModal
