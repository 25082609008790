import React from 'react'
import styles from './styles'
import MainNavBar from './MainNavBar'
import { ApiErrors, ApiHelper, ApiValuesHelper } from '../ApiHelper';
import axios from 'axios';
import LoadingSpinner from './LoadingSpinner';

class MainAuthedPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        currentUser: null,
        mode: props.mode,
        invalidType: null,
        isLoading: true,
        shopId: props.shopId
      };
    }
  
    componentDidMount() {
      const setCurrentUser = (currentUser) => {
        this.setState({ ...this.state, currentUser, isLoading: false })
      }
      const setInvalidType = (errorStatus) => {
        this.setState({ ...this.state, invalidType: ApiErrors.getValueForErrorResponse(errorStatus), isLoading: false })
      }
      axios.get(ApiHelper.GET_CURRENT_USER(), { headers: ApiValuesHelper.defaultHeaders() })
        .then(function (response) { setCurrentUser(response.data) })
        .catch(function (error) { setInvalidType(error.response.status) });
    }

    render() {
        const { currentUser, invalidType, mode, isLoading, shopId } = this.state;

        const setIsLoading = (loadingValue) => {
          this.setState({ ...this.state, isLoading: loadingValue })
        }

        const childrenWithProps = React.Children.map(this.props.children, child => {
          // checking isValidElement is the safe way and avoids a typescript error too
          if (React.isValidElement(child)) {
            return React.cloneElement(child, { mode, currentUser, setIsLoading });
          } else {
            child.props = { ...child.props, currentUser }
          }
          return child;
        });
    
        return (
            <div className="full-page">
                <MainNavBar {...{
                    mode,
                    user: currentUser,
                    shopId
                }}></MainNavBar>
                {isLoading 
                ? <LoadingSpinner></LoadingSpinner>
                : invalidType != null 
                    ? ApiErrors.getErrorPage(invalidType) 
                    : childrenWithProps
                }
            </div>
        );
      }
}

export default MainAuthedPage