import React from "react";
import { Link, Redirect } from "react-router-dom";
import ProfileHeader from "./ProfileHeader";
import MButton from "../shared/custom_components/MButton";
import ResourcePreviewTile from "../resource/ResourcePreviewTile";
import axios from "axios";
import { ApiErrors, ApiErrorValues, ApiHelper } from "../ApiHelper";
import CommentsHolder from "../comments/CommentsHolder";
import SummarizedCommentsHolder from "../comments/SummarizedCommentsHolder";

const Account = ({
  currentUser,
  userId
}) => {
  // Utility
  const [redirectToUrl, setRedirectToUrl] = React.useState(null)

  // Data
  const [myResources, setMyResources] = React.useState([])
  const [resourceSummaries, setResourceSummaries] = React.useState([])

  const openResource = (resourceId) => {
    setRedirectToUrl(`/resource/${resourceId}`)
  }

  const onCreateResource = () => {
    setRedirectToUrl(`/resource/new`)
  }

  const fetchMyResources = () => {
    axios.get(ApiHelper.GET_USER_RESOURCES(userId)) 
    .then(function (response) { response.data.resources != null && setMyResources(response.data.resources) })
    .catch(function (error) { /** TODO */ })
  }

  const fetchCommentSummaries = () => {
    axios.get(ApiHelper.GET_SUMMARY_COMMENTS())
    .then(function (response) { setResourceSummaries(response.data.resources) })
    .catch(function (error) { /** TODO */ })
  }

  React.useEffect(() => {
    if (currentUser.isAdmin) {
      fetchMyResources()
      fetchCommentSummaries()
    }
  }, [userId]);

  return (
    <>
    {currentUser != null && currentUser.id == userId 
    ? <div className="standard-margin">
      <ProfileHeader {...{
        user: currentUser
      }}></ProfileHeader>
      <br/>

      {currentUser.is_admin && <div className="resource-section-holder">
        <div className="display-horizontal title-bar">
          <div><b>featured resources</b></div>
        </div>
      </div>}

      {currentUser.isAdmin ? <div className="resource-section-holder">
        <div className="display-horizontal title-bar">
          <div><b>my resources</b></div>
          <div className="right-offset-button"><MButton onClick={onCreateResource}>create resource</MButton></div>
        </div>
        <div className="resource-box display-horizontal">
          {myResources != null && myResources.length > 0 ? myResources.map(linkedResource => <div key={linkedResource.id} className="link-tile-holder resource-preview-tile-cover" onClick={() => openResource(linkedResource.id)}><ResourcePreviewTile resource={linkedResource}></ResourcePreviewTile></div>) : <div className="empty-text">You don't have any resources. Click create to add some.</div>}
        </div>
      </div> : <div>
        <b>COMING SOON:</b> You will be able to submit questions & suggested articles here. Until then, enjoy browsing the content already posted!
      </div>}

      <br/>
      {currentUser.isAdmin && <div>
        <SummarizedCommentsHolder currentUser={currentUser} resourceSummaries={resourceSummaries}/>
      </div>}

      {redirectToUrl != null && <Redirect to={redirectToUrl} push={true} />}
    </div> 
    : ApiErrors.getErrorPage(ApiErrorValues.NOT_FOUND)}
    </>
  )
}

export default Account
