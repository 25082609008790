import React from "react";
import { Redirect } from "react-router";
import MButton from "../shared/custom_components/MButton";
import CommentsHolder from "./CommentsHolder";

const SummarizedCommentsHolder = (props) => {
    const {
        resourceSummaries,
        currentUser
    } = props

    const [redirectToUrl, setRedirectToUrl] = React.useState(null)

    const addComment = (userId, comment, displayName, resourceId) => {
        // TODO
    }

    return ( 
        <div className="resource-summary-holder">
            <div className="title">Summarized Comments</div>
            {resourceSummaries.map(summary => <div className="resource-summary-item">
                <div className="display-horizontal">
                    <div className="summary-resource-title">{summary.resource_title}</div>
                    <MButton onClick={() => setRedirectToUrl(`/resource/${summary.resource_id}`)}>go to resource</MButton>
                </div>

                <CommentsHolder currentUser={currentUser} addComment={(userId, comment, displayName) => addComment(userId, comment, displayName, summary.resource_id)} comments={summary.comments} />
            </div>)}

            {redirectToUrl != null && <Redirect to={redirectToUrl} push={true} />}
        </div>
    )
}

export default SummarizedCommentsHolder
