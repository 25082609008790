import React from "react";
import { BrowserRouter as Router, Route, Switch, useParams, useLocation } from "react-router-dom";
import Home from "../components/Home";
import SignUp from "../components/SignUp";
import Login from "../components/Login";
import Account from "../components/user/Account";
import MainUnAuthedPage from "../components/shared/MainUnAuthedPage";
import MainAuthedPage from "../components/shared/MainAuthedPage";
import ResourceRouter from "../components/resource/ResourceRouter";
import ResourceEditor from "../components/resource/ResourceEditor";
import ResourceCreator from "../components/resource/ResourceCreator";
import AllResourcesLibary from "../components/resource/AllResourcesLibrary";
import TagsPage from "../components/tags/TagsPage";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default (
  <Router>
    <Switch>
      <Route path="/" exact component={() => {
        return <MainUnAuthedPage>
          <Home></Home>
        </MainUnAuthedPage>
      } } />
      <Route path="/signup" exact component={SignUp} />
      <Route path="/login" exact component={Login} />
      <Route path="/user/:id" exact component={() => {
        let { id } = useParams();
        return <MainAuthedPage>
            <Account userId={id}/> 
          </MainAuthedPage>
        } } />
      <Route path="/resource/new" exact component={() => {
        let query = useQuery();
        return <MainAuthedPage>
          <ResourceCreator parentResourceId={query.get("parent_id")} />
        </MainAuthedPage>
      } } />
      <Route path="/resource/:id" exact component={() => {
        let { id } = useParams();
        return <MainUnAuthedPage>
          <ResourceRouter resourceId={id}></ResourceRouter>
        </MainUnAuthedPage>
      } } />
      <Route path="/resource/:id/edit" exact component={() => {
        let { id } = useParams();
        return <MainAuthedPage>
          <ResourceEditor resourceId={id}/>
        </MainAuthedPage>
      } } />
      <Route path="/tag/:id" exact component={() => {
        let { id } = useParams();
        return <MainUnAuthedPage>
          <ResourceRouter tagId={id}></ResourceRouter>
        </MainUnAuthedPage>
      } } />
      <Route path="/tags" exact component={() => {
        return <MainUnAuthedPage>
          <TagsPage></TagsPage>
        </MainUnAuthedPage>
      } } />
      <Route path="/resources" exact component={() => {
        return <MainUnAuthedPage>
          <AllResourcesLibary />
        </MainUnAuthedPage>
      } } />
    </Switch>
  </Router>
);
