import { TextareaAutosize } from "@material-ui/core";
import React from "react";

const MTextArea = (props) => {
    const {
        value,
        setValue,
        rowsMax = 3,
        placeholder = "message",
        label = "message"
    } = props

    return (
        <div {...props}>
            <TextareaAutosize
            className="message-compose-area"
            rowsMax={rowsMax}
            value={value}
            onChange={e => setValue(e.target.value)}
            aria-label={label}
            placeholder={placeholder}
            defaultValue=""
            style={{ borderRadius: "12px", padding: "8px", width: "100%" }}></TextareaAutosize>
        </div>
    )
}

export default MTextArea
