import React from "react";
import styles from "./styles.scss"

const ResourceEditor = ({
    resource
}) => {
    return (
        <>
        {resource != null 
        ? <div className="standard-margin">
            <div className="title">{resource.display_name}</div>
            <div className="title-sub-note">published by: {resource.publisher.display_name}</div>

            <div className="resource-section-holder notes">
                <div><b>notes</b></div>
                <div>{resource.notes}</div>
            </div>
        </div>
        : <div>loading...</div>}
        </>
    )
}

export default ResourceEditor
