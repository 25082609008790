import React from "react";
import { DEFAULT_PROFILE_URL } from "../shared/CommonValues";
import MButton from "../shared/custom_components/MButton";
import styles from './styles'

const Comment = ({
    comment,
    currentUserId,
    isAdmin = false,
    onDeleteComment
}) => {
    const [canDelete, setCanDelete] = React.useState((comment.publisher != null && comment.publisher.id == currentUserId) || isAdmin)

    return (
        <div className="comment-box">
            <div className="display-horizontal">
                <img className="commenter-image" src={(comment.publisher != null && comment.publisher.profile_image_url != "") ? comment.publisher.profile_image_url : DEFAULT_PROFILE_URL }/>
                <div className="commenter-name">{comment.publisher != null ? comment.publisher.display_name : comment.guest_commenter_name}</div>
                {canDelete && <MButton onClick={() => onDeleteComment(comment.id)} className="comment-delete-button">delete</MButton>}
                <div className="date-published">{comment.date_published}</div>
            </div>
            <div>{comment.body}</div>
        </div>
    )
}

export default Comment