import axios from "axios";
import React from "react";
import { ApiHelper } from "../../ApiHelper";
import MTextArea from "../../shared/custom_components/MTextArea";
import styles from "./styles.scss"
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import MButton from "../../shared/custom_components/MButton";
import { badIframe } from "../../shared/ActionHelper";
import MTextBox from "../../shared/custom_components/MTextBox";

const UrlContentBlock = (props) => {
    const {isAdmin, resourceId, sourceUrlIn} = props

    const [sourceUrl, setSourceUrl] = React.useState(sourceUrlIn)
    const [sourceIframe, setSourceIframe] = React.useState(null)
    const [sourceIframeIsValid, setSourceIframeValidity] = React.useState(false)
    const [sourceUrlPreviewTitle, setSourceUrlPreviewTitle] = React.useState(null)
    const [sourceUrlPreviewImage, setSourceUrlPreviewImage] = React.useState(null)
    const [editingSourceUrl, setEditingSourceUrl] = React.useState(null)

    const updateSourceUrl = () => {
        const data = {
            resource: {
                edit_type: "SOURCE_URL",
                source_url: editingSourceUrl
            }
        }
        axios.patch(ApiHelper.UPDATE_RESOURCE(resourceId), data)
        .then(function (response) { 
            setSourceUrl(response.data.source_url)
            setEditingSourceUrl(null)
        })
        .catch(function (error) { /** TODO */ })
    }

    const getSourceUrlMetaData = (url) => {
        var axios = require("axios").default;

        var options = {
            method: 'GET',
            url: 'https://link-preview4.p.rapidapi.com/',
            params: {url: url, oembed: 'false'},
            headers: {
                'x-rapidapi-host': 'link-preview4.p.rapidapi.com',
                'x-rapidapi-key': process.env.OG_LINK_PREVIEW_KEY
            }
        };

        axios.request(options).then(function (response) {
            const ogpData = response.data.ogp
            var foundImage = false
            var foundTitle = false
            if (ogpData == null) {
                return
            }
            for (const [key, value] of Object.entries(ogpData)) {
                if (key == "og:image") {
                    if (value.length > 0) {
                        foundImage = true
                        setSourceUrlPreviewImage(value[0])
                    }
                }
                if (key == "og:title") {
                    foundTitle = true
                    setSourceUrlPreviewTitle(value)
                }
            }
            if (!foundTitle) {
                setSourceUrlPreviewTitle("No preview is available for this link, click to view in new tab.")
            }
            if (!foundImage) {
                setSourceUrlPreviewImage("https://image.freepik.com/free-photo/blue-light-vintage-painted-pink-camera-against-pink-backdrop_23-2148084243.jpg")
            }
        }).catch(function (error) {
	        setSourceUrlPreviewTitle("No preview is available for this link, click to view in new tab.")
            setSourceUrlPreviewImage("https://image.freepik.com/free-photo/blue-light-vintage-painted-pink-camera-against-pink-backdrop_23-2148084243.jpg")
        });
    }

    React.useEffect(() => {
        if (sourceUrl != null) {
            setSourceIframeValidity(false)
            getSourceUrlMetaData(sourceUrl)
            // TODO: Fix this
            // badIframe(sourceUrl).then(result => {
            //     if (result) {
            //         setSourceIframeValidity(false)
            //         getSourceUrlMetaData(sourceUrl)
            //     } else {
            //         setSourceIframeValidity(true)
            //     }
            // })
        }
    }, [sourceUrl]);

    return (
        <div {...{props}} className="resource-section-holder content">
            <div className="display-horizontal">
                <div><b>content</b></div>
                <div className="spacer"/>
                {isAdmin && editingSourceUrl != null ? <div><SaveIcon className="clickable-icon" onClick={updateSourceUrl}/> <CancelIcon className="clickable-icon" onClick={() => setEditingSourceUrl(null)}/></div> : <EditIcon className={isAdmin ? "clickable-icon hide-til-hover-child" : "hide-item"} onClick={() => setEditingSourceUrl(sourceUrl)}/>}
                {sourceUrl != null && <MButton onClick={() => window.open(`${sourceUrl}`,'_blank')} style={{ position: "absolute", right: "16px" }} variant="contained">view in new tab</MButton>}
            </div>
            {sourceUrl != null && <br/>}
            {isAdmin && editingSourceUrl != null && <MTextArea value={editingSourceUrl} setValue={setEditingSourceUrl}/>}
            <div>
                {sourceIframeIsValid 
                ? <iframe src={sourceUrl} title={sourceUrl} width="100%" height="300"></iframe>
                : <div className="preview-url-box" onClick={() => window.open(`${sourceUrl}`,'_blank')}>
                    {sourceUrlPreviewImage != null && <img className="source-preview-image" src={sourceUrlPreviewImage} />}
                    {sourceUrlPreviewTitle != null && <MTextBox className="source-preview-text">
                        {sourceUrlPreviewTitle}
                    </MTextBox>}
                </div>}
            </div>
        </div>
    )
}

export default UrlContentBlock
