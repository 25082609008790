import React from "react";
import BaseModal from "./BaseModal";
import MButton from "./MButton";
import styles from './image.scss'
import styles2 from './styles.scss'
import FileUpload from "./FileUpload";

const UpdateImageOptionsModal = (props) => {
    const [selectedFileData, setSelectedFileData] = React.useState(null)

    return (
        <BaseModal>
            <div className="display-horizontal">
                <div className="title">image options</div>
                <MButton onClick={props.onClose} className="close-modal-button">close</MButton>
            </div>
            
            <div className="display-horizontal standard-margin">
                <div className="display-vertical">
                    <div>current image</div>
                    <img className="large-image-preview" src={props.imageUrl ? props.imageUrl : "https://image.freepik.com/free-vector/illustration-gallery-icon_53876-27002.jpg"}/>
                </div>

                {/* <div className="vertical-image-divider"></div> */}

                <div className="display-vertical file-upload-holder">
                    <div className="image-label">select new</div>
                    <FileUpload label={"select a new image"} updateFilesCb={(files) => setSelectedFileData(files[0])}/>
                    <MButton disabled={selectedFileData == null} variant="contained" onClick={() => props.onSaveImage(selectedFileData)}>save</MButton>
                </div>
            </div>
        </BaseModal>
    )
}

export default UpdateImageOptionsModal
