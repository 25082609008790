import React from "react";
import { DEFAULT_FOLDER_PREVIEW_IMAGE, DEFAULT_RESOURCE_PREVIEW_IMAGE } from "../shared/CommonValues";
import styles from "./styles.scss"
import CancelIcon from '@mui/icons-material/Cancel';
import MTextBox from "../shared/custom_components/MTextBox";

const ResourcePreviewTile = (props) => {
    const {
        resource,
        onClick,
        showRemoveIcon = false,
        onRemove
    } = props

    const onRemoveClicked = (e) => {
        e.stopPropagation()
        onRemove(resource.id)
    }

    return (
        <div onClick={onClick} {...props} className="resource-preview-tile" style={{ height: "inherit" }}>
            <img src={resource.preview_image_url != null ? resource.preview_image_url : resource.is_folder ? DEFAULT_FOLDER_PREVIEW_IMAGE : DEFAULT_RESOURCE_PREVIEW_IMAGE} className="preview-image"></img>
            {resource.is_folder && <div className="folder-cover">folder</div>}
            {showRemoveIcon && <CancelIcon className="remove-icon-cover" onClick={onRemoveClicked}/>}
            <MTextBox className="preview-title-cover" maxLines={3}><b>{resource.display_name}</b></MTextBox>
        </div>
    )
}

export default ResourcePreviewTile
