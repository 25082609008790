import React from "react";
import { Link, Redirect } from "react-router-dom";
import styles from './styles'
import { DEFAULT_PROFILE_URL } from './CommonValues'
import { ApiValuesHelper } from "../ApiHelper";
import { isMobile } from "./GlobalHelper";
import MenuIcon from '@mui/icons-material/Menu';

const MainNavBar = ({
  user = null
}) => {
  const [shouldShowMenu, setShouldShowMenu] = React.useState(false)
  const [shouldShowLoggedOutMenu, setShouldShowLoggedOutMenu] = React.useState(false)
    const [redirectToUrl, setRedirectToUrl] = React.useState(null)

  const callLogouut = () => {
    ApiValuesHelper.clearAccessToken()
    const url = `/api/v1/user/logout`;
      fetch(url, { method: 'POST' })
        .then(response => {
          setRedirectToUrl(`/`)
        })
        .catch(() => {});
  }

  const redirectHome = () => { setRedirectToUrl(`/`) }

  return (
    <div className="main-nav-bar">
      <div className="display-horizontal">
        <h2 className="clickable-title" onClick={redirectHome}><b>the canceled culture</b></h2>
      </div>

      {user == null 
        ? isMobile() ? <div className="right-justify-menu">
          <MenuIcon className="mobile-menu-icon" onClick={() => setShouldShowLoggedOutMenu(!shouldShowLoggedOutMenu)}/>
        </div>
        : <div className="right-justify-menu">
            <Link to="/signup"
              className="btn custom-button"
              role="button">Sign Up</Link>

            <Link to="/login"
              className="btn custom-button toolbar-button"
              role="button">Login</Link>
          </div>
      : <div className="right-justify-menu display-horizontal">
          {/* {showMessagesIcon && <Link to={mode == SHOP_MODE ? `/shop/${shopId}/messages` : `/seller/${user.id}/messages`}
              className="toolbar-button"
              role="button"><MessagesIcon {...{
                unreadCount: getUnreadCount()
              }}></MessagesIcon></Link>} */}
          <img className="profile-icon" src={user.profile_image_url != "" ? user.profile_image_url : DEFAULT_PROFILE_URL} onClick={() => setShouldShowMenu(!shouldShowMenu)}></img>
        </div>
      }

      {shouldShowMenu && <div className="account-menu">
      <div className="account-menu-row" onClick={() => redirectHome()}>Home</div>
          <div className="account-menu-row" onClick={() => setRedirectToUrl(`/user/${user.id}`)}>My Account</div>
          <div className="account-menu-row" onClick={callLogouut}>Logout</div>
        </div>}

        {shouldShowLoggedOutMenu && <div className="mobile-account-menu">
          <div className="account-menu-row" onClick={() => setRedirectToUrl(`/signup`)}>Sign Up</div>
          <div className="account-menu-row" onClick={() => setRedirectToUrl(`/login`)}>Login</div>
        </div>}

        {redirectToUrl != null && <Redirect to={redirectToUrl} push={true} />}

    </div>
  )
  }

export default MainNavBar
