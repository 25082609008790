import React from "react";
import { Link, Redirect } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ResourcePreviewTile from "./ResourcePreviewTile";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MButton from '../shared/custom_components/MButton'
import CreateFolderModal from "../shared/custom_components/CreateFolderModal";
import axios from "axios";
import { ApiHelper } from "../ApiHelper";
import ErrorMessage from "../shared/custom_components/ErrorMessage";
import { useHistory } from "react-router-dom";
import { isMobile } from "../shared/GlobalHelper";

const Library = ({
    isAdmin,
    parentResource,
    tagCollection,
    backLinkId
}) => {
    const [redirectToUrl, setRedirectToUrl] = React.useState(null)
    const [resources, setResources] = React.useState([])
    const [showCreateFolderModal, setShowCreateFolderModal] = React.useState(false)

    const [errorMessage, setErrorMessage] = React.useState(null)
    let history = useHistory();

    React.useEffect(() => {
        setResources(parentResource.sub_links != null ? parentResource.sub_links : [])
    }, [parentResource]);

    const onResourceSelected = (resourceId) => {
        setRedirectToUrl(`/resource/${resourceId}`)
    }

    const onAddToFolderClicked = () => {
        setRedirectToUrl(`/resource/new?parent_id=${parentResource.id}`)
    }

    const onAddNewFolderClicked = () => {
        setErrorMessage(null)
        setShowCreateFolderModal(true)
    }

    const createNewFolder = (folderName) => {
        setErrorMessage(null)
        setShowCreateFolderModal(false)
        const data = {
            display_name: folderName
        }
        axios.post(ApiHelper.CREATE_FOLDER(parentResource.id), data)
        .then(function (response) { setResources([...resources, response.data]) })
        .catch(function (error) { setErrorMessage("Failed to create folder. Try again later.") })
    }

    return (
        <div className="standard-margin">
            {parentResource != null && <div>
                <div className={isMobile() ? "display-vertical" : "display-horizontal"}>
                    <div className="display-horizontal">
                    <ArrowBackIcon className="back-arrow" onClick={() => history.goBack()}/>
                    <div className="display-vertical">
                        {parentResource.isTag && <div>tag</div>}
                        <h2>{parentResource.display_name}</h2>
                    </div>
                    </div>

                    <div className="display-horizontal">
                    {isAdmin && !parentResource.isTag && <div className="add-resource-button"><MButton onClick={onAddToFolderClicked}>add new resource</MButton></div>}
                    {isAdmin && !parentResource.isTag && <div className="add-resource-button"><MButton onClick={onAddNewFolderClicked}>add new folder</MButton></div>}
                    </div>
                </div>
                <br/>
                {errorMessage != null && <ErrorMessage message={errorMessage}/>}
                {resources.length > 0 ? 
                <div className="resource-page display-horizontal">
                    {resources.map(resource => <div className="resource-preview-tile-cover">
                        <ResourcePreviewTile onClick={() => onResourceSelected(resource.id)} resource={resource}></ResourcePreviewTile>
                    </div> )}
                </div> 
                : <div className="empty-text">{parentResource.isTag ? "No resources have been tagged." : "This folder is empty."}</div>}
            </div>}

            {redirectToUrl != null && <Redirect to={redirectToUrl} push={true} />}
            {showCreateFolderModal && <CreateFolderModal onClose={() => setShowCreateFolderModal(false)} onCreateFolder={createNewFolder}/>}
        </div>
    )
}

export default Library
