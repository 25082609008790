import React from 'react'
import style from './loadingStyles.scss'

const LoadingSpinner = () => {
    return (
        <div className="sk-chase" style={{ color: "black" }}>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
        </div>
    )
}

export default LoadingSpinner;