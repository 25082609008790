import React from 'react'
import styles from './styles'
import { Link } from 'react-router-dom'

const NotLoggedInPage = ({

}) => {
    return (
        <div className="full-error-page">
            <div className="center-message">
                <img className="header-image" src="https://media2.giphy.com/media/13MxcuMx98TCz6/200.webp?cid=ecf05e4728ejkcth81qntvocjt48riezwmp8t896b5aeu9dz&rid=200.webp&ct=g"></img>
            </div>
            <div className="center-message">
                <h1>you are not logged in</h1>
            </div>
        </div>
    )
}

export default NotLoggedInPage