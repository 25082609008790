import React from "react";
import { DEFAULT_PROFILE_URL } from "../shared/CommonValues";
import MButton from "../shared/custom_components/MButton";
import MTextArea from "../shared/custom_components/MTextArea";
import styles from './styles'

const CommentEditor = ({
    currentUser,
    addComment
}) => {
    const generateRandomName = () => {
        return "Anonymous"
    }

    const [comment, setComment] = React.useState("")
    const [displayName, setDisplayName] = React.useState((currentUser != null && currentUser.display_name != null) ? currentUser.display_name : generateRandomName())

    const onAddComment = () => {
        if (currentUser != null) {
            addComment(currentUser.id, comment)
        } else {
            addComment(null, comment, displayName)
        }
        setComment("")
    }

    return (
        <div>
            <div className="display-horizontal">
                <img className="commenter-image" src={(currentUser != null && currentUser.profile_image_url != "") ? currentUser.profile_image_url : DEFAULT_PROFILE_URL }/>
                <div className="commenter-name">{displayName}</div>
            </div>
            <div className="display-horizontal comment-write-bar">
                <MTextArea className="comment-text-area" value={comment} setValue={setComment}/>
                <MButton className="add-comment-button" onClick={onAddComment} variant="contained" color="secondary">add comment</MButton>
            </div>
        </div>
    )
}

export default CommentEditor
