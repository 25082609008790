import axios from "axios";
import React from "react";
import { Redirect } from "react-router-dom";
import { ApiHelper } from "../ApiHelper";
import CommentsHolder from "../comments/CommentsHolder";
import MButton from "../shared/custom_components/MButton";
import TagsHolder from "../shared/custom_components/TagsHolder";
import TagSelectionModal from "../tags/TagSelectionModal";
import ResourcePreviewTile from "./ResourcePreviewTile";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import MTextArea from "../shared/custom_components/MTextArea";
import UrlContentBlock from "./content-rendrs/UrlContentBlock";
import AddLinkedResourceModal from "./links/AddLinkedResourceModal";
import ImageHolder from "../shared/custom_components/ImageHolder";
import UpdateImageOptionsModal from "../shared/custom_components/UpdateImageOptionsModal";
import ErrorMessage from "../shared/custom_components/ErrorMessage";
import { getBase64 } from "../shared/GlobalHelper";
import RawTxtContentBlock from "./content-rendrs/RawTxtContentBlock";
import MTextBox from "../shared/custom_components/MTextBox";

const ResourceDetail = ({
    currentUser,
    isAdmin,
    resource
}) => {
    const [redirectToUrl, setRedirectToUrl] = React.useState(null)
    const [titleErrorMessage, setTitleErrorMessage] = React.useState(null)
    const [notesErrorMessage, setNotesErrorMessage] = React.useState(null)
    const [sourceUrlErrorMessage, setSourceUrlErrorMessage] = React.useState(null)
    const [sourceInfoErrorMessage, setSourceInfoErrorMessage] = React.useState(null)
    const [tagErrorMessage, setTagErrorMessage] = React.useState(null)
    const [linksErrorMessage, setLinksErrorMessage] = React.useState(null)
    const [commentsErrorMessage, setCommentsErrorMessage] = React.useState(null)
    const [previewImageErrorMessage, setPreviewImageErrorMessage] = React.useState(null)
    const [deleteResourceErrorMessage, setDeleteResourceErrorMessage] = React.useState(null)

    const [tags, setTags] = React.useState(resource.tags)
    const [title, setTitle] = React.useState(resource.display_name)
    const [sourcePreviewUrl, setSourcePreviewUrl] = React.useState(resource.preview_image_url)
    const [sourceInfo, setSourceInfo] = React.useState(resource.source_info)
    const [notes, setNotes] = React.useState(resource.notes)
    const [subLinks, setSubLinks] = React.useState(resource.sub_links)
    const [comments, setComments] = React.useState(resource.comments_ordered != null ? resource.comments_ordered : [] )
    const [commentsCount, setCommentsCount] = React.useState(resource.comments_count)

    const [showSelectTagModal, setShowSelectTagModal] = React.useState(false)
    const [showSelectLinkModal, setShowSelectLinkModal] = React.useState(false)
    const [showEditPreviewImageModal, setShowEditPreviewImageModal] = React.useState(false)
    const [showConfirmDelete, setShowConfirmDelete] = React.useState(false)

    const [editingTitle, setEditingTitle] = React.useState(null)
    const [editingNotes, setEditingNotes] = React.useState(null)
    const [editingSourceInfo, setEditingSourceInfo] = React.useState(null)
    const [addTagErrorMessage, setAddTagErrorMessage] = React.useState(null)

    const onShare = () => {
        // TODO: Add sharing options.
    }

    const onTagPicked = (tag) => {
        setAddTagErrorMessage(null)
        setShowSelectTagModal(false)
        axios.post(ApiHelper.TAG_RESOURCE(resource.id, tag.id), {})
        .then(function (response) { 
            setTags([...tags, tag])
        })
        .catch(function (error) { 
            if (error.response.data.error_type == "DUPLICATE") {
                setAddTagErrorMessage(`It looks like the tag "${tag.display_name}" has already been applied to this resource, we're going to skip adding the duplicate.`)
            } else {
                setAddTagErrorMessage("Failed to add this tag. Make sure you have permissions to do this.")
            }
         })
    }

    const removeTag = (tag) => {
        setAddTagErrorMessage(null)
        axios.patch(ApiHelper.UNTAG_RESOURCE(resource.id, tag.id))
        .then(function (response) {
            loadResourceTags()
        })
        .catch(function (error) {
            setAddTagErrorMessage("Failed to remove this tag. Make sure you have permissions to do this.")
        })
    }

    const loadResourceTags = () => {
        setAddTagErrorMessage(null)
        axios.get(ApiHelper.GET_RESOURCE_TAGS(resource.id))
        .then(function (response) {
            setTags(response.data.tags)
        })
        .catch(function (error) {
            setAddTagErrorMessage("Something went wrong trying to load these tags. Sorry for the inconvenience, check back later.")
        })
    }

    const goToResource = (resourceId) => {
        setRedirectToUrl(`/resource/${resourceId}`)
    }

    const onTagSelected = (tag) => {
        setRedirectToUrl(`/tag/${tag.id}`)
    }

    const updateTitle = () => {
        setTitleErrorMessage(null)
        const data = {
            resource: {
                edit_type: "TITLE",
                display_name: editingTitle
            }
        }
        axios.patch(ApiHelper.UPDATE_RESOURCE(resource.id), data)
        .then(function (response) { 
            setTitle(response.data.display_name)
            setEditingTitle(null)
        })
        .catch(function (error) { setTitleErrorMessage("Failed to update notes. Make sure you have permissions to do this.") })
    }

    const updateNotes = () => {
        setNotesErrorMessage(null)
        const data = {
            resource: {
                edit_type: "NOTES",
                notes: editingNotes
            }
        }
        axios.patch(ApiHelper.UPDATE_RESOURCE(resource.id), data)
        .then(function (response) { 
            setNotes(response.data.notes)
            setEditingNotes(null)
        })
        .catch(function (error) { setNotesErrorMessage("Failed to update notes. Make sure you have permissions to do this.") })
    }

    const updateSourceInfo = () => {
        setSourceInfoErrorMessage(null)
        const data = {
            resource: {
                edit_type: "SOURCE_INFO",
                source_info: editingSourceInfo
            }
        }
        axios.patch(ApiHelper.UPDATE_RESOURCE(resource.id), data)
        .then(function (response) { 
            setSourceInfo(response.data.source_info)
            setEditingSourceInfo(null)
        })
        .catch(function (error) { setSourceInfoErrorMessage("Failed to update background info. Make sure you have permissions to do this.") })
    }

    const loadSubLinks = () => {
        setLinksErrorMessage(null)
        axios.get(ApiHelper.GET_RESOURCE_SUBLINKS(resource.id))
        .then(function (response) { setSubLinks(response.data.sub_links) }) 
        .catch(function (error) { setLinksErrorMessage("Something went wrong trying to load these links. Sorry for the inconvenience, check back later.") })
    }

    const addResourceLink = (subLinkId) => {
        setLinksErrorMessage(null)
        axios.post(ApiHelper.ADD_RESOURCE_LINK(resource.id), { sub_link_id: subLinkId })
        .then(function (response) { 
            setShowSelectLinkModal(false)
            setSubLinks([...subLinks, response.data.sub_link])
        })
        .catch(function (error) {
            setLinksErrorMessage("Failed to link this resource. Make sure you have permissions to do this.")
        })
    }

    const unlinkResource = (subLinkId) => {
        setLinksErrorMessage(null)
        axios.patch(ApiHelper.UNLINK_RESOURCE(resource.id, subLinkId), {})
        .then(function (response) { loadSubLinks() })
        .catch(function (error) { setLinksErrorMessage("Failed to unlink this resource. Make sure you have permissions to do this.") })
    }

    const loadComments = () => {
        setCommentsErrorMessage(null)
        axios.get(ApiHelper.GET_RESOURCE_COMMENTS(resource.id))
        .then(function (response) { setComments(response.data.comments) }) 
        .catch(function (error) { setCommentsErrorMessage("Something went wrong loading the comments for this resource. Sorry for the inconvenience, check back later.") })
    }

    const addComment = (commenterId, comment, displayName) => {
        setCommentsErrorMessage(null)
        const data = {
            body: comment,
            publisher_id: commenterId,
            display_name: displayName
        }
        axios.post(ApiHelper.ADD_COMMENT(resource.id), data)
        .then(function (response) { 
            setCommentsCount(commentsCount + 1)
            setComments([response.data, ...comments]) 
        })
        .catch(function (error) { setCommentsErrorMessage("Failed to add comment. Sorry for the inconvenience, try again later.") }) 
    }

    const onDeleteComment = (commentId) => {
        setCommentsErrorMessage(null)
        axios.patch(ApiHelper.DELETE_COMMENT(commentId), {})
        .then(function () { loadComments() })
        .catch(function () { setCommentsErrorMessage("Failed to unlink this resource. Make sure you have permissions to do this.") })
    }

    const updateResourcePreviewImageUrl = (previewUrl) => {
        const data = {
            preview_image_url: previewUrl
        }
        axios.patch(ApiHelper.UPDATE_RESOURCE_PREVIEW_IMAGE(resource.id), data)
        .then(function (response) { 
            setShowEditPreviewImageModal(false)
            setSourcePreviewUrl(response.data.preview_image_url) 
        })
        .catch(function (error) { /** TODO */ })
    }

    const updateImage = (imageData) => {
        setPreviewImageErrorMessage(null)
        setShowEditPreviewImageModal(false)
        var blobData = null
        getBase64(imageData, (inputData) => {
            if (inputData == null) {
                setPreviewImageErrorMessage("Selected file cannot be read.")
                return
            }
            blobData = inputData

            const data = {
                image_data: blobData
            }
            axios.patch(ApiHelper.UPDATE_RESOURCE_PREVIEW_IMAGE(resource.id), data)
            .then(function (response) { 
                setShowEditPreviewImageModal(false)
                setSourcePreviewUrl(response.data.preview_image_url) 
            })
            .catch(function (error) { setPreviewImageErrorMessage(`Failed to update image. ${error.response.data.error_type}`) })
        })
    }

    const onDeleteResource = () => {
        axios.delete(ApiHelper.DELETE_RESOURCE(resource.id))
        .then(function (response) {
            setRedirectToUrl("/")
        })
        .catch(function (error) {
            setDeleteResourceErrorMessage("Failed to delete resource. You may not have permissions for this.: " + error.response.data.error_message)
        })
    }

    return (
        <div className="standard-margin">
            <div className="display-horizontal title-box">
                <ImageHolder imageUrl={sourcePreviewUrl} onEditImage={() => setShowEditPreviewImageModal(true)}/>
                <div className="display-vertical">
                    <div className="display-horizontal">
                        {(isAdmin && editingTitle != null) ? <MTextArea value={editingTitle} setValue={setEditingTitle}/> : <MTextBox className="title" maxLines={4}>{title}</MTextBox>}
                        {isAdmin && (editingTitle != null ? <div className="display-vertical"><SaveIcon className="clickable-icon" onClick={updateTitle}/> <CancelIcon className="clickable-icon" onClick={() => setEditingTitle(null)}/></div> : <EditIcon className={isAdmin ? "clickable-icon hide-til-hover-child" : "hide-item"} onClick={() => setEditingTitle(title)}/>)}
                    </div>
                    <div className="title-sub-note">published by: {resource.publisher.display_name}</div>
                    {previewImageErrorMessage != null && <ErrorMessage message={previewImageErrorMessage}/>}
                </div>
            </div>

            <div className="resource-section-holder notes">
                <div className="display-horizontal">
                    <div><b>notes</b></div>
                    <div className="spacer"/>
                    {isAdmin && editingNotes != null ? <div><SaveIcon className="clickable-icon" onClick={updateNotes}/> <CancelIcon className="clickable-icon" onClick={() => setEditingNotes(null)}/></div> : <EditIcon className={isAdmin ? "clickable-icon hide-til-hover-child" : "hide-item"} onClick={() => setEditingNotes(resource.notes)}/>}
                </div>
                {(notes == "" || notes == null) && <div className="empty-text">This resource does not have any notes.</div>}
                {isAdmin && editingNotes != null ? <MTextArea value={editingNotes} setValue={setEditingNotes}/> : <div>{notes}</div>}
                {notesErrorMessage != null && <ErrorMessage message={notesErrorMessage}/>}
            </div>

            <div className="layered-content-holder">
                { resource.file_type == "HTML"
                ? <UrlContentBlock resourceId={resource.id} isAdmin={isAdmin} sourceUrlIn={resource.source_url}/>
                : <RawTxtContentBlock resourceId={resource.id} isAdmin={isAdmin} content={resource.body}/>}
                <div className="resource-section-holder source">
                    <div className="display-horizontal">
                        <div><b>source background</b></div>
                        <div className="spacer"/>
                        {isAdmin && editingSourceInfo != null ? <div><SaveIcon className="clickable-icon" onClick={updateSourceInfo}/> <CancelIcon className="clickable-icon" onClick={() => setEditingSourceInfo(null)}/></div> : <EditIcon className={isAdmin ? "clickable-icon hide-til-hover-child" : "hide-item"} onClick={() => setEditingSourceInfo(resource.source_info)}/>}
                    </div>
                    <div>
                        {(sourceInfo == "" || sourceInfo == null) && <div className="empty-text">This resource does not have any background notes.</div>}
                        {isAdmin && editingSourceInfo != null ? <MTextArea value={editingSourceInfo} setValue={setEditingSourceInfo}/> : <div>{sourceInfo}</div>}
                        {sourceInfoErrorMessage != null && <ErrorMessage message={sourceInfoErrorMessage}/>}
                    </div>
                </div>
            </div>

            <div className="resource-section-holder linked-resources">
                <div className="display-horizontal bottom-spaced-section">
                    <div><b>linked resources</b></div>
                    {isAdmin && <div className="add-resource-button"><MButton onClick={() => setShowSelectLinkModal(true)}>add link</MButton></div>}
                </div>
                <div className="display-horizontal details-links-holder">
                    {subLinks.length <= 0 && <div className="empty-text">This resource does not have any links.</div>}
                    {subLinks.map(linkedResource => <div key={linkedResource.id} className="details-link-tile-holder" onClick={() => goToResource(linkedResource.id)}><ResourcePreviewTile resource={linkedResource} showRemoveIcon={isAdmin} onRemove={unlinkResource}></ResourcePreviewTile></div>)}
                </div>
                {linksErrorMessage != null && <ErrorMessage message={linksErrorMessage}/>}
            </div>

            <div className="resource-section-holder-empty">
                <div className="display-horizontal bottom-spaced-section">
                    <div><b>tags</b></div>
                    {isAdmin && <div className="add-resource-button"><MButton onClick={() => setShowSelectTagModal(true)}>add tags</MButton></div>}
                </div>
                {tags.length <= 0 && <div className="empty-text">This resource does not have any tags.</div>}
                <TagsHolder onTagClicked={onTagSelected} tags={tags} showRemoveIcon={isAdmin} onRemoveClicked={removeTag}></TagsHolder>
                {addTagErrorMessage != null && <ErrorMessage message={addTagErrorMessage}/>}
            </div>

            <div className="resource-section-holder comments">
                <div className="display-horizontal">
                    <div><b>comments</b></div>
                    <br/>
                    <div>({commentsCount})</div>
                </div>
                {commentsErrorMessage != null && <ErrorMessage message={commentsErrorMessage}/>}
                <CommentsHolder currentUser={currentUser} comments={comments} addComment={addComment} isAdmin={isAdmin} onDeleteComment={onDeleteComment}></CommentsHolder>
                {comments.length <= 0 && <div className="empty-text">This resource does not have any commets yet.</div>}
            </div>

            <br/>
            {isAdmin && !showConfirmDelete && <MButton onClick={() => setShowConfirmDelete(true)} color="error">delete resource</MButton>}

            <br/>
            {redirectToUrl != null && <Redirect to={redirectToUrl} push={true} />}
            {showSelectTagModal && <TagSelectionModal onClose={() => setShowSelectTagModal(false)} onTagPicked={onTagPicked}/>}
            {showSelectLinkModal && <AddLinkedResourceModal onClose={() => setShowSelectLinkModal(false)} onLinkResource={addResourceLink}/>}
            {showEditPreviewImageModal && <UpdateImageOptionsModal onClose={() => setShowEditPreviewImageModal(false)} imageUrl={sourcePreviewUrl} onSaveImage={updateImage}/>}
            {showConfirmDelete && <div>
                <div><b>Are you sure you want to delte this resource?</b></div>
                <div>This action is permanent and this resource will no longer exist.</div>
                {deleteResourceErrorMessage != null && <ErrorMessage message={deleteResourceErrorMessage}/>}
                <div className="display-horizontal">
                    <MButton color="error" variant="contained" onClick={onDeleteResource}>confirm delete</MButton>
                    <div className="add-resource-button"><MButton onClick={() => setShowConfirmDelete(false)}>never mind</MButton></div>
                </div>
                <br/>
            </div>}
        </div>
    )
}

export default ResourceDetail
