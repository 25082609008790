import React from "react";
import styles from './error.scss'
import ErrorIcon from '@mui/icons-material/Error';

const ErrorMessage = (props) => {
    const { message } = props

    return (
        <div {...{props}} className="display-horizontal error-holder">
            <ErrorIcon className="error-icon"/>
            <div className="error-message">{message}</div>
        </div>
    )
}

export default ErrorMessage
