import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import { ApiHelper } from "../ApiHelper";
import { DEFAULT_PROFILE_URL } from "../shared/CommonValues";
import ErrorMessage from "../shared/custom_components/ErrorMessage";
import MButton from "../shared/custom_components/MButton";
import MTextField from "../shared/custom_components/MTextField";
import UpdateImageOptionsModal from "../shared/custom_components/UpdateImageOptionsModal";
import { getBase64 } from "../shared/GlobalHelper";
import styles from './styles'

const ProfileHeader = ({
    user
}) => {
      const [isEditingFullName, setIsEditingFullName] = React.useState(false)
      const [isEditingDisplayName, setIsEditingDisplayName] = React.useState(false)

      const [profileUrl, setProfileUrl] = React.useState(user.profile_image_url)
      const [editFullNameText, setEditFullNameTet] = React.useState(user.first_name)
      const [editDisplayNameText, setEditDisplayNameText] = React.useState(user.display_name)

      const [previewImageErrorMessage, setPreviewImageErrorMessage] = React.useState(null)

      const [showEditProfileImageModal, setShowEditProfileImageModal] = React.useState(false)

      const updateDisplayName = () => {
        const data = {
          updates: {
            display_name: editDisplayNameText
          }
        }
        axios.patch(ApiHelper.UPDATE_USER(user.id), data)
        .then(function (response) { user.display_name = response.data.display_name })
        .catch(function (error) { /** TODO */ })
      }

      const saveNewDisplayName = () => {
        user.display_name = editDisplayNameText
        setIsEditingDisplayName(!isEditingDisplayName)
        updateDisplayName(user.id, editDisplayNameText)
      }

      const updateImage = (imageData) => {
        setPreviewImageErrorMessage(null)
        setShowEditProfileImageModal(false)
        var blobData = null
        getBase64(imageData, (inputData) => {
            if (inputData == null) {
                setProfileImageErrorMessage("Selected file cannot be read.")
                return
            }
            blobData = inputData

            const data = {
                image_data: blobData
            }
            axios.patch(ApiHelper.UPDATE_USER_PROFILE_IMAGE(user.id), data)
            .then(function (response) { 
                setProfileUrl(response.data.profile_image_url) 
            })
            .catch(function (error) { 
              setPreviewImageErrorMessage(`Failed to update image. ${error.response.data.error_type}`) 
            })
        })
      }

      return (
        <div className="profile-header">
          <div className="image-holder">
            <img className="image" src={profileUrl != "" ? profileUrl : DEFAULT_PROFILE_URL } alt="No Image"></img>
            <div className="edit-image-banner" onClick={() => setShowEditProfileImageModal(true)}>edit</div>
            {previewImageErrorMessage != null && <ErrorMessage messsage={previewImageErrorMessage}/>}
          </div>
          <div className="profile-info">
            <h2 className="full-name" >{user.first_name} {user.last_name}</h2>
            <div className="horizontal-text">
              <p className="title-text">DISPLAY NAME</p>
              { isEditingDisplayName 
                ? <div className="editing-profile-display-name">
                    <MTextField value={editDisplayNameText} setValue={setEditDisplayNameText}/>
                    <MButton onClick={saveNewDisplayName}>Save</MButton>
                  </div>
                : <p className="profile-display-name" onClick={() => setIsEditingDisplayName(!isEditingDisplayName)}>{user.display_name}</p> }
            </div>
          </div>

          {showEditProfileImageModal && <UpdateImageOptionsModal onClose={() => setShowEditProfileImageModal(false)} imageUrl={profileUrl} onSaveImage={updateImage}/>}
        </div>
      )
    }


export default ProfileHeader
