import Button from '@mui/material/Button';
import React from "react";

const MButton = (props) => {
    return (
        <div {...props}>
            <Button disabled={props.disabled} variant={props.variant ? props.variant : "outlined"} color={props.color ? props.color : "primary"}>{props.children}</Button>
        </div>
    )
}

export default MButton