import React from 'react'
import styles from './styles'
import MainNavBar from './MainNavBar'
import { ApiHelper, ApiValuesHelper } from '../ApiHelper';
import axios from 'axios';

class MainUnAuthedPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        currentUser: null
      };
    }
  
    componentDidMount() {
      const setCurrentUser = (currentUser) => {
        this.setState({ ...this.state, currentUser })
      }
      axios.get(ApiHelper.GET_CURRENT_USER(), { headers: ApiValuesHelper.defaultHeaders() })
        .then(function (response) { setCurrentUser(response.data) })
        .catch(function (error) { /** do nothing, auth not required */ });
    }

    render() {
      const { currentUser } = this.state;
        const childrenWithProps = React.Children.map(this.props.children, child => {
            // checking isValidElement is the safe way and avoids a typescript error too
            if (React.isValidElement(child)) {
              return React.cloneElement(child, { currentUser });
            }
            return child;
          });

        return (
            <div className="full-page">
                <MainNavBar {...{
                  user: this.state.currentUser
                }}></MainNavBar>
                {childrenWithProps}
            </div>
        );
      }
  
}

export default MainUnAuthedPage
