import axios from "axios";
import React from "react";
import { Redirect } from "react-router";
import { ApiHelper } from "../ApiHelper";
import ErrorMessage from "../shared/custom_components/ErrorMessage";
import { isMobile } from "../shared/GlobalHelper";
import ResourcePreviewTile from "./ResourcePreviewTile";
import styles from './styles'

const AllResourcesLibary = (props) => {
    const { parentResourceId, currentUser } = props

    const [redirectToUrl, setRedirectToUrl] = React.useState(null)

    const [resources, setResources] = React.useState([])
    const [errorMessage, setErrorMessage] = React.useState(null)

    const loadSubLinks = () => {
        axios.get(ApiHelper.GET_RESOURCE_SUBLINKS(parentResourceId))
        .then(function (response) { setResources(response.data.sub_links) })
        .catch(function (error) { setErrorMessage("Failed to load resources. Try again later.") })
    }

    const loadResources = () => {
        axios.get(ApiHelper.GET_ALL_RESOURCES)
        .then(function (response) { setResources(response.data.resources) })
        .catch(function (error) { setErrorMessage("Failed to load resources. Try again later.") })
    }

    const goToResource = (resourceId) => { setRedirectToUrl(`/resource/${resourceId}`) }

    React.useEffect(() => {
        if (parentResourceId != null) {
            loadSubLinks()
        } else {
            loadResources()
        }
    }, [parentResourceId]);

    return (
        <div>
            {errorMessage != null && <ErrorMessage message={errorMessage}/>}
            <div className={isMobile() ? "" : "display-horizontal resource-page"}>
                {resources.map(resource => <div className={isMobile() ? "resource-library-tile-mobile" : "resource-library-tile"}><ResourcePreviewTile resource={resource} onClick={() => goToResource(resource.id)}/></div>)}
            </div>

            {redirectToUrl != null && <Redirect to={redirectToUrl} push={true} />}
        </div>
    )
}

export default AllResourcesLibary
