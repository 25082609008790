import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from 'react-redux'
import styles from './styles'
import { Button, TextField } from '@material-ui/core';
import { Form } from "semantic-ui-react";
import MainNavBar from "./shared/MainNavBar";
import { ApiHelper, ApiValuesHelper } from './ApiHelper'
import axios from "axios";
import MTextField, { MTextValidationResponse, ValidationIndicatorType, ValidationStatus } from "./shared/custom_components/MTextField";
import ErrorMessage from "./shared/custom_components/ErrorMessage";

function checkUsername(username) {
    return axios.get(ApiHelper.CHECK_USERNAME_VALIDITY(username), { headers: ApiValuesHelper.defaultHeaders() })
    .then(function (response) { 
        return response.data.validity 
    })
    .catch(function (error) { return false });
}

const SignUp = ({
    currentUser
}) => {
    const [redirectToUrl, setRedirectToUrl] = React.useState(null)

    const [firstName, setFirstName] = React.useState("")
    const [lastName, setLastName] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [username, setUserName] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [confirmPassword, setConfirmPassword] = React.useState("")
    const [accessCode, setAccessCode] = React.useState("")

    const [errorMessage, setErrorMessage] = React.useState(null)
    const [usernameError, setUsernameError] = React.useState(false)
    const [passwordError, setPasswordError] = React.useState(false)
    const [confirmPasswordError, setConfirmPasswordError] = React.useState(false)

    const [toolTipMessage, setTooltipMessage] = React.useState("Some fields are still required.")
    const [isValidated, setIsValidated] = React.useState(false)

    const validateAndSetUsername = (value) => {
        setUserName(value)
        var usernameIsTaken = value == "test"
        setUsernameError(usernameIsTaken && value != "")
    }

    const validateAndSetPassword = (value) => {
        setPassword(value)
        var passwordMeetsStrengthRequirements = value.length > 8
        setPasswordError(!passwordMeetsStrengthRequirements && value != "")
    }

    const validateAndSetConfirmPassword = (value) => {
        setConfirmPassword(value)
        var passwordsMatch = password == confirmPassword
        setConfirmPasswordError(!passwordsMatch || value == "")
    }

    const validateConfirmPassword = () => {
        if (password == confirmPassword && password != "") {
            setConfirmPasswordError(false)
            return MTextValidationResponse(ValidationStatus.VALID, "")
        }
        setConfirmPasswordError(true)
        return MTextValidationResponse(ValidationStatus.NOT_VALID, "")
    }

    const validateEmail = () => {
        if (email.includes("@") && email.includes(".")) {
            return MTextValidationResponse(ValidationStatus.VALID, "")
        }
        return MTextValidationResponse(ValidationStatus.NOT_VALID, "")
    }

    const validateUsername = (input) => {
        var validity = checkUsername(input).then(result => {
            var status = ValidationStatus.NOT_VALID
            var message = "this username is available"
            if (result == true) {
                setUsernameError(false)
                status = ValidationStatus.VALID
                message = "this username is available"
            } else {
                setUsernameError(true)
                status = ValidationStatus.NOT_VALID
                message = "this username is not available, try something else"
            }
            return { status, message }
        });
        return validity
    }

    const createUser = (data) => {
        setErrorMessage(null)
        axios.post(ApiHelper.CREATE_NEW_USER, data, { headers: ApiValuesHelper.defaultHeaders() })
        .then(function (response) {
            setRedirectToUrl(`/user/${response.data.user_id}`)
        })
        .catch(function (error) {
            setErrorMessage("Something went wrong creating a new user. Sorry for the inconvenience, try again later.")
        });
    }

    const onSignupClicked = () => {
        let data = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            username: username,
            password: password,
            confirm_password: confirmPassword,
            access_code: accessCode
        }
        createUser(data)
    }

    return (
        <div>
            <MainNavBar></MainNavBar>
            <div className="login-page-content">
            <h1>Sign Up</h1>

            <div className="signup-column">
                <TextField placeholder="First Name" value={firstName} onChange={e => setFirstName(e.target.value)} required={true} label={"First Name"}></TextField>
                <TextField placeholder="Last Name" value={lastName} onChange={e => setLastName(e.target.value)} required={true} label={"Last Name"}></TextField>
                <MTextField required={true} label="Email" placeholder="Email" {...{ value: email, setValue: setEmail, validation: validateEmail, validationIndicator: ValidationIndicatorType.ICON_END }}></MTextField>
                <MTextField required={true} label="Username" placeholder="Username" {...{ value: username, setValue: setUserName, validation: validateUsername, validationIndicator: ValidationIndicatorType.ICON_END }}></MTextField>

                {/* <TextField placeholder="Username" value={username} onChange={e => validateAndSetUsername(e.target.value)} required={true} error={usernameError} label={usernameError ? "Username is already taken - try mike123" : "Username"}></TextField> */}
                <TextField placeholder="Password" value={password} onChange={e => validateAndSetPassword(e.target.value)} required={true} error={passwordError} type="password" label={passwordError ? "Password is not strong enough." : "Password"}></TextField>

                <MTextField required={true} label={confirmPasswordError ? "Password does not match." : "Confirm Password"} placeholder="Confirm Password" {...{ value: confirmPassword, setValue: setConfirmPassword, validation: validateConfirmPassword, validationIndicator: ValidationIndicatorType.ICON_END }} type="password"></MTextField>
                {/* <TextField placeholder="Confirm Password" value={confirmPassword} onChange={e => validateAndSetConfirmPassword(e.target.value)} required={true} error={confirmPasswordError} type="password" label={confirmPasswordError ? "Password does not match." : "Confirm Password"}></TextField> */}
                {/* <TextField placeholder="Access Code" value={accessCode} onChange={e => setAccessCode(e.target.value)} required={true} label={"Access Code"}></TextField>  */}
            </div>
            {errorMessage != null && <ErrorMessage message={errorMessage}/>}

            <Button className="btn btn-lg custom-button" onClick={onSignupClicked}>Sign up</Button>

            <Link
                to="/login"
                className=""
                role="button"
            >
                Already have an account? Login
            </Link>
            </div>
            {redirectToUrl != null && <Redirect to={redirectToUrl} push={true} />}
        </div>
    )
}

export default SignUp;
