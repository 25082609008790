import React from 'react'
import styles from './modal_styles'

const BaseModal = (props) => {
      return (
        <div className='popup'>
          <div className='qr-popup_inner'>
              {props.children}
          </div>
        </div>
      );
  }

  export default BaseModal;


//   {/* <div className="qr-scrollable-content">
//                 <QRCode 
//                   entityType={entityType}
//                   entityId={entityId}
//                   associatedPath={associatedPath}
//                 ></QRCode>  
//               </div>
//               <Button className="baseButton" onClick={onClose}>{closeButtonText}</Button> */}