import React from "react";
import { Link, Redirect } from "react-router-dom";
import styles from './styles'
import { Button, TextField } from '@material-ui/core';
import MainNavBar from './shared/MainNavBar';
import { ApiHelper, ApiValuesHelper } from './ApiHelper'
import axios from "axios";
import ErrorMessage from "./shared/custom_components/ErrorMessage";

const Login = ({
    currentUser
}) => {
    const [username, setUserName] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [redirectToUser, setRedirectToUser] = React.useState(null)
    const [errorMessage, setErrorMessage] = React.useState(null)

    const selectErrorMessage = (errorType) => {
        if (errorType == "INVALID_LOGIN") {
            setErrorMessage("Hmm... that username or password is not correct.")
        } else {
            setErrorMessage("Oh no! Something went wrong, sorry for the inconvenience, try again later.")
        }
    }

    const authenticateUser = (data) => {
        setErrorMessage(null)
        axios.post(ApiHelper.AUTHENTICATE_USER, data, { headers: ApiValuesHelper.defaultHeaders() })
            .then(function (response) {
                setRedirectToUser(response.data.user_id)
            })
            .catch(function (error) {
                selectErrorMessage(error.response.data.error_type)
            });
    }

    const onLoginClicked = () => {
        let data = {
            username: username,
            password: password
        }
        authenticateUser(data)
    }

    return (
        <div className="login-page">
            <MainNavBar {...{
                shouldShowLogout: false
            }}></MainNavBar>
            <div className="login-page-content">
            <h1>Login</h1>
            <div className="signup-column">
                <TextField placeholder="Username" value={username} onChange={e => setUserName(e.target.value)}></TextField>
                <TextField placeholder="Password" type="password" value={password} onChange={e => setPassword(e.target.value)}></TextField>
            </div>
            {errorMessage && <ErrorMessage message={errorMessage}/>}

            <Button className="btn btn-lg custom-button" onClick={onLoginClicked}>Login</Button>

            <Link
                to="/signup"
                className="toolbar-button"
                role="button"
            >
                Don't have an account? Sign Up
            </Link>
            </div>

            {redirectToUser != null && <Redirect to={'/user/' + redirectToUser} push={true} />}
        </div>
    )
}

export default Login
