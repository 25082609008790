import React from "react";
import styles from './styles.scss'
import MButton from "../shared/custom_components/MButton";
import TagCreatorModule from "./TagCreatorModule";
import TagPickerModule from "./TagPickerModule";
import BaseModal from "../shared/custom_components/BaseModal";
import { isMobile } from "../shared/GlobalHelper";

const TagSelectionModal = ({
    onTagPicked,
    onClose
}) => {
    const [showPicker, setShowPicker] = React.useState(true)

    return (
        <BaseModal>
            <div className="display-vertical">
                <div className="display-horizontal">
                    <div className={showPicker ? "title" : "unselected-title"} onClick={() => setShowPicker(true)}>select existing</div>
                    <div className="horizontal-spacer"/>
                    <div className={showPicker ? "unselected-title" : "title"} onClick={() => setShowPicker(false)}>create</div>
                </div>
                {showPicker 
                ? <div className="display-vertical max-content-fill">
                    <TagPickerModule onTagPicked={onTagPicked}/>
                </div>
                : <div className="display-vertical">
                    <TagCreatorModule onTagCreated={onTagPicked}/>
                </div>}
            </div>
            <MButton onClick={onClose}>close</MButton>
        </BaseModal>
    )
}

export default TagSelectionModal
