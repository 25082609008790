import React from 'react'
import styles from './styles'
import { Link } from 'react-router-dom'

const ExpiredSessionPage = ({

}) => {
    return (
        <div className="full-error-page">
            <div className="center-message">
                <img className="header-image" src="https://media4.giphy.com/media/CZGcUfnAy3ayJw2eZX/giphy.gif?cid=ecf05e47b7yncqw7assw5yvjbupebf8ztaaen2gpw0pqtqdp&rid=giphy.gif&ct=g"></img>
            </div>
            <div className="center-message">
                <h2>your session has expired, log back in</h2>
            </div>
        </div>
    )
}

export default ExpiredSessionPage