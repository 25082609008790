import React from 'react'
import styles from './image.scss'
import { DEFAULT_RESOURCE_PREVIEW_IMAGE } from '../CommonValues'

const ImageHolder = (props) => {
    return (
        <div {...props} className="image-holder">
            <img className="image" src={props.imageUrl != "" && props.imageUrl != null ? props.imageUrl : DEFAULT_RESOURCE_PREVIEW_IMAGE } alt="No Image"></img>
            <div onClick={props.onEditImage} className="edit-image-banner">edit</div>
        </div>
    )
}

export default ImageHolder
