
export const DEFAULT_PROFILE_URL = "https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png"
export const DEFAULT_GROUP_URL = "https://icons-for-free.com/iconfiles/png/512/group+people+team+users+icon-1320196240876938595.png"

export const DEFAULT_FOLDER_PREVIEW_IMAGE = "https://image.freepik.com/free-vector/illustration-folder-with-document_53876-28502.jpg" //"https://image.freepik.com/free-vector/illustration-data-folder-icon_53876-6329.jpg"
export const DEFAULT_RESOURCE_PREVIEW_IMAGE = "https://image.freepik.com/free-vector/illustration-business-agreement-concept_53876-43716.jpg" //"https://image.freepik.com/free-vector/illustration-paper_53876-5860.jpg"

export const mapStatusToPillColor = (status) => {
    switch(status) {
        case "PENDING":
            return "blue"
        case "LOCKED":
            return "purple"
        case "ACTIVE":
            return "green"
        case "ACCEPTED":
            return "green"
        case "REVOKED":
            return "red"
        default:
            return "gray"
    }
}
