import React from "react";
import BaseModal from "../../shared/custom_components/BaseModal";
import MButton from "../../shared/custom_components/MButton";
import AddLinkedResourceModule from "./AddLinkedResourceModule";
import styles from './styles.scss'

const AddLinkedResourceModal = ({
    onLinkResource,
    onClose
}) => {
    const [subLinkId, setSubLinkId] = React.useState(null)

    const onLinkSelected = (linkId) => setSubLinkId(linkId)

    return (
        <BaseModal>
            <div className="title">select a resource</div>
            <div className="resource-section-cover"><AddLinkedResourceModule onLinkSelected={onLinkSelected} selectedId={subLinkId}/></div>
            <div className="display-horizontal action-button-holder">  
                <MButton onClick={() => onLinkResource(subLinkId)} disabled={subLinkId == null}>Link Selected Resource</MButton>
                <MButton onClick={onClose} className="end-button">close</MButton>
            </div>
        </BaseModal>
    )
}

export default AddLinkedResourceModal
