import { Tooltip } from "@mui/material";
import React from "react";
import styles from './styles.scss'

const MTextBox = (props) => {
    const {
        maxLines = 2,
        textOverflow = "ellipsis"
    } = props

    //style={{ textOverflow: textOverflow, maxLines: maxLines, overflow: "hidden" }} {...props}>

    return (
        <Tooltip title={props.children}>
        <div {...props} > 
            <div className="text-overflow" style={{ width: "100%", height: "100%", WebkitLineClamp: `${maxLines}`, textOverflow: textOverflow }}>
                {props.children}
            </div>
        </div>
        </Tooltip>
    )
}

export default MTextBox
