import axios from "axios";
import React from "react";
import { ApiHelper } from "../../ApiHelper";
import ResourcePreviewTile from "../ResourcePreviewTile";

const AddLinkedResourceModule = ({
    onLinkSelected,
    selectedId
}) => {
    const [resources, setResources] = React.useState([])
    const [initialized, setInitialized] = React.useState(false)

    const loadResources = () => {
        axios.get(ApiHelper.GET_ALL_RESOURCES)
        .then(function (response) { setResources(response.data.resources) })
        .catch(function (error) {  })
    }

    React.useEffect(() => {
        if (initialized == false) {
            setInitialized(true)
            loadResources()
        }
    }, [initialized]);

    return (
        <div className="display-horizontal resource-holder">
            {resources.length <= 0 && <div className="empty-text">There are no resources available.</div>}
            {resources.map(resource => <div key={resource.id} className={selectedId == resource.id ? "selected-preview-tile-cover" : "preview-tile-cover"} onClick={() => onLinkSelected(resource.id)}><ResourcePreviewTile resource={resource}></ResourcePreviewTile></div>)}
        </div>
    )
}

export default AddLinkedResourceModule
